import React from 'react';
import './UseCases.css';
import dataLabelingImage from '../assets/images/data-labeling.png';  // Example image path
import syntheticDataImage from '../assets/images/synthetic-data.png';  // Example image path
import fineTuningImage from '../assets/images/fine-tuning.png';  // Example image path
import rlhfImage from '../assets/images/rlhf.png';  // Example image path
import demoGif from '../assets/images/demo.gif'; // You'll need to add this

const UseCases = () => {
  return (
    <section className="use-cases" id="use-cases">
      <h2>Fine-tune your model in 3 easy steps</h2>
      <div className="use-cases-container">
        <div className="steps-container">
          <div className="step-block">
            <div className="step-number">1</div>
            <div className="step-content">
              <h3>Setup your model</h3>
              <p>Configure your AI model parameters and training preferences in our intuitive console.</p>
            </div>
          </div>

          <div className="step-block">
            <div className="step-number">2</div>
            <div className="step-content">
              <h3>Upload your data</h3>
              <p>Import your training data through our secure platform with automated validation and preprocessing.</p>
            </div>
          </div>

          <div className="step-block">
            <div className="step-number">3</div>
            <div className="step-content">
              <h3>Connect via API</h3>
              <p>Integrate seamlessly with our RESTful API to deploy your model in production environments.</p>
            </div>
          </div>
        </div>
        
        <div className="demo-container">
          {/* Add your GIF here */}
          <img src={demoGif} alt="Platform Demo" className="demo-gif" />
        </div>
      </div>
    </section>
  );
}

export default UseCases;

