import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from './auth';
import Header from './components/Header';
import MainSection from './components/MainSection';
import UseCases from './components/UseCases';
import GetStarted from './components/GetStarted';
import Blog from './components/Blog';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AdminUpload from './components/AdminUpload';
import { ModelsProvider } from './context/ModelsContext';
import Sandbox from './components/dashboard/Sandbox';

import './App.css';

const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/login" />;
};

const AppContent = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isDashboardPage = location.pathname.startsWith('/dashboard');
  const isUploadPage = location.pathname === '/upload';

  return (
    <div className="App">
      {!isLoginPage && !isDashboardPage && !isUploadPage && <Header />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/dashboard/*" 
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        <Route path="/dashboard/sandbox" element={<Sandbox />} />
        <Route path="/" element={
          <>
            <MainSection />
            <UseCases />
            <GetStarted />
            <Blog />
            {/*<AboutUs />*/}
          </>
        } />
        <Route path="/upload" element={<AdminUpload />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {!isLoginPage && !isDashboardPage && !isUploadPage && <Footer />}
    </div>
  );
};

function App() {
  return (
    <ModelsProvider>
      <Router>
        <AppContent />
      </Router>
    </ModelsProvider>
  );
}

export default App;