import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DashboardComponents.css';
import { useModels } from '../../context/ModelsContext';

const ModelDetails = () => {
  const { modelId } = useParams();
  const { models, loading, error } = useModels();
  const [activeTab, setActiveTab] = useState('overview');
  
  // New state variables for chat functionality
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const messagesEndRef = useRef(null);

  // Scroll to bottom of chat whenever messages change
  useEffect(() => {
    if (messagesEndRef.current && activeTab === 'chat') {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, activeTab]);

  // Initialize chat with welcome message when tab is first opened
  useEffect(() => {
    if (activeTab === 'chat' && messages.length === 0 && !loading && models.length > 0) {
      const foundModel = models.find(m => m.id === modelId);
      if (foundModel) {
        setMessages([{
          type: 'system',
          content: `This is ${foundModel.name}, how can I help you today?`
        }]);
      }
    }
  }, [activeTab, messages.length, loading, models, modelId]);
  
  if (loading) {
    return (
      <div className="dashboard-page">
        <div className="page-header">
          <h1>Loading model details...</h1>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-page">
        <div className="page-header">
          <h1>Error</h1>
          <p className="error-message">{error}</p>
        </div>
      </div>
    );
  }

  const model = models.find(m => m.id === modelId) || {
    id: modelId,
    name: 'Not Found',
    status: 'ERROR',
    provider: 'N/A',
    baseModel: 'N/A',
    persona: 'Model not found',
    dataset: { trainingSize: 0, testSize: 0 },
    trainingConfig: { learningRate: 0, numEpochs: 0, batchSize: 'auto' },
    createdAt: 'N/A',
    apiEndpoint: 'N/A'
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // Add toast notification here
    alert('Copied to clipboard!'); // Replace with proper toast
  };

  // Handle input changes
  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  // Handle form submission
  const handleSendMessage = async (e) => {
    if (e) e.preventDefault();
    if (!inputMessage.trim() || isSending) return;
    
    // Add user message to chat
    const userMessage = { type: 'user', content: inputMessage };
    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsSending(true);
    
    try {
      // Updated to use SEC API endpoint with proper Bearer token format
      const response = await fetch('https://api.tagflow-ai.com/tagflow-ai/api/sec', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer sk-tagflow-0d7e1dbc-ada7-4638-a9b5-b400eace6c27'
        },
        body: JSON.stringify({
          input: userMessage.content
        })
      });
      
      const data = await response.json();
      console.log('API Response:', data); // Add logging to debug
      
      if (data.status === 'success') {
        // Add AI response to chat
        setMessages(prev => [...prev, { 
          type: 'system', 
          content: data.response 
        }]);
      } else {
        // Handle API error
        setMessages(prev => [...prev, { 
          type: 'error', 
          content: 'Sorry, I encountered an error processing your request.' 
        }]);
        console.error('API Error:', data);
      }
    } catch (error) {
      // Handle network error
      setMessages(prev => [...prev, { 
        type: 'error', 
        content: 'Sorry, there was a problem connecting to the server.' 
      }]);
      console.error('Request Error:', error);
    } finally {
      setIsSending(false);
    }
  };

  // Handle Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>{model.name}</h1>
        <div className={`model-status ${model.status.toLowerCase()}`}>
          {model.status}
        </div>
      </div>

      <div className="model-details-tabs">
        <button 
          className={activeTab === 'overview' ? 'active' : ''} 
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button 
          className={activeTab === 'dataset' ? 'active' : ''} 
          onClick={() => setActiveTab('dataset')}
        >
          Dataset
        </button>
        <button 
          className={activeTab === 'chat' ? 'active' : ''} 
          onClick={() => setActiveTab('chat')}
        >
          Chat
        </button>
        <button 
          className={activeTab === 'api' ? 'active' : ''} 
          onClick={() => setActiveTab('api')}
        >
          API Integration
        </button>
        <button 
          className={activeTab === 'weights' ? 'active' : ''} 
          onClick={() => setActiveTab('weights')}
        >
          Export Weights
        </button>
      </div>

      {activeTab === 'overview' && (
        <div className="details-section">
          <h2>Model Information</h2>
          <div className="info-grid">
            <div className="info-item">
              <label>Provider</label>
              <span>{model.provider}</span>
            </div>
            <div className="info-item">
              <label>Base Model</label>
              <span>{model.baseModel}</span>
            </div>
            <div className="info-item">
              <label>AI Persona</label>
              <span>{model.persona}</span>
            </div>
            <div className="info-item">
              <label>Learning Rate</label>
              <span>{model.trainingConfig.learningRate}</span>
            </div>
            <div className="info-item">
              <label>Training Epochs</label>
              <span>{model.trainingConfig.numEpochs}</span>
            </div>
            <div className="info-item">
              <label>Training Set Size</label>
              <span>{model.dataset.trainingSize}</span>
            </div>
            <div className="info-item">
              <label>Test Set Size</label>
              <span>{model.dataset.testSize}</span>
            </div>
            <div className="info-item">
              <label>Created At</label>
              <span>{model.createdAt}</span>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'chat' && (
        <div className="details-section">
          <h2>Chat with your Model</h2>
          <div className="chat-interface">
            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div 
                  key={index} 
                  className={`message ${msg.type === 'user' ? 'user-message' : msg.type === 'error' ? 'error-message' : 'system-message'}`}
                >
                  <p>{msg.content}</p>
                </div>
              ))}
              {isSending && (
                <div className="message system-message typing">
                  <p>Thinking...</p>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
            
            <form onSubmit={handleSendMessage} className="chat-input-container">
              <input 
                type="text" 
                className="chat-input" 
                placeholder="Type your message here..." 
                value={inputMessage}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                disabled={isSending}
              />
              <button 
                type="submit" 
                className="chat-send-btn"
                disabled={isSending || !inputMessage.trim()}
              >
                {isSending ? 'Sending...' : 'Send'}
              </button>
            </form>
          </div>
        </div>
      )}

      {activeTab === 'api' && (
        <div className="details-section">
          <h2>API Integration</h2>
          
          <div className="api-endpoints-container">
            <div className="api-endpoint">
              <h3>Chat API</h3>
              <p>Use this endpoint to chat with your fine-tuned model.</p>
              <div className="code-block">
                <pre>
                  {`curl -X POST \\
  https://api.tagflow-ai.com/tagflow-ai/api/chat \\
  -H "Authorization: Bearer $TAGFLOW_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"input": "What is your question? Write it here!", "model": "${model.name}"}'`}
                </pre>
                <button onClick={() => copyToClipboard(`curl -X POST \\
  https://api.tagflow-ai.com/tagflow-ai/api/chat \\
  -H "Authorization: Bearer $TAGFLOW_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"input": "What is your question? Write it here!", "model": "${model.name}"}'`)}>
                  Copy
                </button>
              </div>
            </div>
            
            <div className="api-endpoint">
              <h3>Upload Additional Data</h3>
              <p>Enhance your model by uploading additional training data.</p>
              <div className="code-block">
                <pre>
                  {`curl -X POST \\
  https://api.tagflow-ai.com/tagflow-ai/api/upload \\
  -H "Authorization: Bearer $TAGFLOW_API_KEY" \\
  -H "Content-Type: multipart/form-data" \\
  -F "model=${model.name}" \\
  -F "file=@path/to/your/data.jsonl"`}
                </pre>
                <button onClick={() => copyToClipboard(`curl -X POST \\
  https://api.tagflow-ai.com/tagflow-ai/api/upload \\
  -H "Authorization: Bearer $TAGFLOW_API_KEY" \\
  -H "Content-Type: multipart/form-data" \\
  -F "model=${model.name}" \\
  -F "file=@path/to/your/data.jsonl"`)}>
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'weights' && (
        <div className="details-section">
          <h2>Export Weights</h2>
          <div className="weights-grid">
            <div className="weight-format">
              <h3>LoRA: BF16</h3>
              <button className="download-btn">Download Weights</button>
            </div>
            <div className="weight-format">
              <h3>LoRA: FP16</h3>
              <button className="download-btn">Download Weights</button>
            </div>
            <div className="weight-format">
              <h3>Merged: BF16</h3>
              <button className="download-btn">Download Weights</button>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'dataset' && (
        <div className="details-section">
          <h2>Dataset Content</h2>
          <div className="dataset-viewer">
            <div className="dataset-header">
              <span>Text Dataset</span>
              <button 
                className="copy-btn"
                onClick={() => copyToClipboard(model.dataset.content)}
              >
                Copy Dataset
              </button>
            </div>
            <pre className="dataset-content">
              {model.dataset.content || 'No dataset content available'}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModelDetails;