import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentApiKey } from '../../auth';
import './DashboardComponents.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faSpinner, faArrowLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const Sandbox = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiError, setApiError] = useState(null);
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const key = getCurrentApiKey();
    if (key) {
      setApiKey(key);
    }
    
    // Add an initial greeting message
    setMessages([
      {
        role: 'assistant',
        content: 'Hello! I\'m the TagflowAI Document Research Agent. Ask me anything tou would like to research and I will generate a report.'
      }
    ]);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    const userMessage = {
      role: 'user',
      content: inputMessage
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);
    setApiError(null);

    try {
      console.log('Sending request to API with:', {
        query: userMessage.content,
        breadth: 4,
        depth: 2,
        format: "report"
      });
      
      const response = await fetch('http://44.210.122.209:3051/api/research', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: userMessage.content,
          breadth: 4,
          depth: 2,
          format: "report"
        })
      });

      const data = await response.json();
      console.log('API response:', data);

      if (response.ok) {
        if (data.success && data.answer) {
          // Format the response to include the answer and possibly the learnings
          let responseContent = data.answer;
          
          if (data.learnings && data.learnings.length > 0) {
            responseContent += "\n\nKey Learnings:";
            data.learnings.forEach((learning, index) => {
              responseContent += `\n${index + 1}. ${learning}`;
            });
          }
          
          const assistantMessage = {
            role: 'assistant',
            content: responseContent
          };
          setMessages(prev => [...prev, assistantMessage]);
        } else {
          const assistantMessage = {
            role: 'assistant',
            content: "Hi, I'm a deep research agent that help you research and generate reports!"
          };
          setMessages(prev => [...prev, assistantMessage]);
        }
      } else {
        setApiError(`Error ${response.status}: ${data.error || data.message || 'Unknown error'}`);
        const errorMessage = {
          role: 'assistant',
          content: `Sorry, there was an error processing your request. Status: ${response.status}`
        };
        setMessages(prev => [...prev, errorMessage]);
      }
    } catch (error) {
      console.error('Error calling API:', error);
      setApiError(error.message);
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error connecting to the service. Please check your network connection.'
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <button className="back-button" onClick={handleBackClick}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <h1>Document Research Agent Sandbox</h1>
        <p>Interact with our advanced AI agent designed for document research</p>
      </div>
      
      <div className="sandbox-container">
        <div className="model-info">
          <div className="model-badge">
            <span className="model-name">deep-research-agent-sandbox</span>
            <span className="model-status active">Active</span>
          </div>
          <p className="model-description">
            This research agent is intended for trial and testing period, talk to us to become a design partner.
          </p>
        </div>
        
        {apiError && (
          <div className="api-error-banner">
            <FontAwesomeIcon icon={faExclamationTriangle} /> 
            API Error: {apiError}
            <button onClick={() => setApiError(null)}>Dismiss</button>
          </div>
        )}
        
        <div className="chat-area">
          {messages.map((message, index) => (
            <div 
              key={index} 
              className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'}`}
            >
              <div className="message-content">
                {message.content}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="message assistant-message">
              <div className="message-content loading">
                <FontAwesomeIcon icon={faSpinner} spin /> Thinking...
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        
        <form className="chat-input-form" onSubmit={handleSubmit}>
          <input
            type="text"
            value={inputMessage}
            onChange={handleInputChange}
            placeholder="Ask about documents, legal matters, regulations..."
            disabled={isLoading}
            className="chat-input"
          />
          <button 
            type="submit" 
            disabled={isLoading || !inputMessage.trim()}
            className="send-button"
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Sandbox; 