import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//import { sendPdfNotificationEmail } from '../../services/emailServices';
import { sendNotificationEmail } from '../../services/emailServices';
import './DashboardComponents.css';
import { getCurrentUser } from '../../auth';
import { PDFDocument } from 'pdf-lib';
import JSZip from 'jszip';

const BASE_MODELS = [
  { value: 'mistral-7b', label: 'Mistral 7B' },
  { value: 'mistral-3b', label: 'Mistral 3B' },
  { value: 'mistral-small', label: 'Mistral Small' },
  { value: 'codestral', label: 'Codestral' },
  { value: 'mistral-large-2', label: 'Mistral Large 2' },
  { value: 'llama-3.1-8b', label: 'Llama 3.1 8B Instruct' },
  { value: 'llama-3.1-70b', label: 'Llama 3.1 70B Instruct' },
  { value: 'qwen-2.5-72b', label: 'Qwen 2.5 72B Instruct' },
  { value: 'qwen-2.5-32b', label: 'Qwen 2.5 32B Coder' }
];

async function combinePDFs(pdfFiles) {
  const mergedPdf = await PDFDocument.create();
  
  for (const pdfFile of pdfFiles) {
    const pdfBytes = await pdfFile.arrayBuffer();
    const pdf = await PDFDocument.load(pdfBytes);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => mergedPdf.addPage(page));
  }
  
  const mergedPdfFile = await mergedPdf.save();
  const combinedFile = new File([mergedPdfFile], 'combined_training_data.pdf', { type: 'application/pdf' });
  return combinedFile;
}

const FineTune = () => {
  const [trainingDatasets, setTrainingDatasets] = useState([]);
  const [baseModel, setBaseModel] = useState('mistral-7b');
  const [modelName, setModelName] = useState('');
  const [aiPersona, setAiPersona] = useState('');
  const [fileType, setFileType] = useState('pdf');
  const [learningRate] = useState(0.0001);
  const [epochs] = useState(10);
  const [trainTestSplit] = useState(80);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [inputFormat, setInputFormat] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [examples, setExamples] = useState([
    { input: '', output: '' }
  ]);

  const getAcceptedFileTypes = (type) => {
    const acceptMap = {
      'pdf': 'application/pdf',
      'image': 'image/jpeg, image/png',
      'text': 'text/plain'
    };
    return acceptMap[type];
  };

  const handleAddDataset = (files) => {
    const newFiles = Array.from(files).filter(file => {
      const acceptedTypes = {
        'pdf': ['application/pdf'],
        'image': ['image/jpeg', 'image/png'],
        'text': ['text/plain']
      };
      
      if (!acceptedTypes[fileType].includes(file.type)) {
        alert(`Please upload ${fileType.toUpperCase()} files only`);
        return false;
      }
      return true;
    });

    if (newFiles.length === 0) return;

    const newDatasets = newFiles.map(file => ({
      file,
      type: fileType,
      name: file.name
    }));

    setPdfFile(newFiles[0]); // Keep for backward compatibility
    setTrainingDatasets(prev => [...prev, ...newDatasets]);
  };

  const handleRemoveDataset = (index) => {
    setPdfFile(null);
    setTrainingDatasets([]);
  };

  const handleAddExample = () => {
    setExamples([...examples, { input: '', output: '' }]);
  };

  const handleExampleChange = (index, field, value) => {
    const updatedExamples = [...examples];
    updatedExamples[index][field] = value;
    setExamples(updatedExamples);
  };

  const handleRemoveExample = (index) => {
    const updatedExamples = examples.filter((_, i) => i !== index);
    setExamples(updatedExamples);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (trainingDatasets.length === 0) {
        throw new Error(`Please upload at least one ${fileType.toUpperCase()} file`);
      }
  
      if (!modelName.trim()) {
        throw new Error('Please enter a model name');
      }
  
      if (!aiPersona.trim()) {
        throw new Error('Please enter an AI persona description');
      }
  
      const currentUser = getCurrentUser();
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
  
      // Combine files if multiple files exist
      let finalFile;
      if (trainingDatasets.length > 1) {
        switch (fileType) {
          case 'pdf':
            const pdfFiles = trainingDatasets.map(dataset => dataset.file);
            finalFile = await combinePDFs(pdfFiles);
            break;
  
          case 'image':
            const zip = new JSZip();
            for (let i = 0; i < trainingDatasets.length; i++) {
              const file = trainingDatasets[i].file;
              await zip.file(`image_${i + 1}${getFileExtension(file.name)}`, file);
            }
            const zipContent = await zip.generateAsync({ type: 'blob' });
            finalFile = new File([zipContent], 'combined_images.zip', { type: 'application/zip' });
            break;
  
          case 'text':
            const textContents = await Promise.all(
              trainingDatasets.map(async (dataset) => {
                const text = await dataset.file.text();
                return `=== ${dataset.file.name} ===\n${text}\n\n`;
              })
            );
            const combinedText = textContents.join('');
            finalFile = new File([combinedText], 'combined_text.txt', { type: 'text/plain' });
            break;
  
          default:
            finalFile = trainingDatasets[0].file;
        }
      } else {
        finalFile = trainingDatasets[0].file;
      }
  
      const trainingData = {
        userEmail: currentUser,
        modelName,
        aiPersona,
        baseModel: BASE_MODELS.find(m => m.value === baseModel)?.label || baseModel,
        learningRate: learningRate.toExponential(2),
        epochs,
        trainTestSplit,
        inputFormat,
        outputFormat,
        examples,
        file: finalFile,
        fileType
      };
  
      const emailResult = await sendNotificationEmail(
        'New File Fine-Tuning Job Started',
        trainingData
      );
  
      if (!emailResult) {
        throw new Error('Failed to send email notification');
      }
  
      setShowPopup(true);
      
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    } catch (error) {
      console.error('Training job submission failed:', error);
      alert(`Failed to start training job: ${error.message || 'Please check your inputs and try again'}`);
    }
  };
  
  // Add this helper function if not already present
  const getFileExtension = (filename) => {
    return filename.substring(filename.lastIndexOf('.'));
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>Document File Training</h1>
        <p>Choose your dataset, input-output formatting example, and easily launch a model training on your data.</p>
      </div>
      
      <form className="fine-tune-form" onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-col">

            <div className="form-section">
              <label className="section-label">Model Name</label>
              <input
                type="text"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                placeholder="Enter a name for your fine-tuned model"
                className="model-select"
                required
              />
            </div>

            <div className="form-section">
              <label className="section-label">Describe your AI Model</label>
              <textarea
                value={aiPersona}
                onChange={(e) => setAiPersona(e.target.value)}
                placeholder="Describe the AI's persona (e.g., 'An experienced corporate lawyer specializing in contract law with 15 years of experience...')"
                className="persona-textarea"
                rows={4}
                required
              />
            </div>

            <div className="form-section">
              <label className="section-label">Base model</label>
              <select 
                value={baseModel} 
                onChange={(e) => setBaseModel(e.target.value)}
                className="model-select"
              >
                {BASE_MODELS.map(model => (
                  <option key={model.value} value={model.value}>{model.label}</option>
                ))}
              </select>
            </div>

            <div className="form-section">
              <label className="section-label">File Type</label>
              <select 
                value={fileType} 
                onChange={(e) => {
                  setFileType(e.target.value);
                  setTrainingDatasets([]);
                  setPdfFile(null);
                }}
                className="model-select"
              >
                <option value="pdf">PDF (.pdf)</option>
                <option value="image">Image (.jpg, .jpeg, .png)</option>
                <option value="text">Text (.txt)</option>
              </select>
            </div>

          </div>

          <div className="form-col">
            <div className="form-section">
              <label className="section-label">Training Datasets</label>
              <div className="datasets-upload-area">
                <input
                  type="file"
                  multiple
                  accept={getAcceptedFileTypes(fileType)}
                  onChange={(e) => handleAddDataset(e.target.files)}
                />
                <div className="upload-icon">📁</div>
                <div className="upload-text">
                  Drag and drop your {fileType.toUpperCase()} {fileType === 'image' ? 'files (JPG, PNG)' : 
                    fileType === 'pdf' ? 'files' : 
                    'files (.txt)'} here
                </div>
                <div className="upload-hint">or click to browse</div>
              </div>
              
              {trainingDatasets.length > 0 && (
                <div className="file-list">
                  {trainingDatasets.map((dataset, index) => (
                    <div key={index} className="file-item">
                      <span className="file-name">{dataset.name}</span>
                      <button 
                        type="button"
                        onClick={() => handleRemoveDataset(index)}
                        className="remove-file"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="format-section">
              <label className="section-label">Input/Output Format (optional, default: chat)</label>
              <div className="format-inputs">
                <textarea
                  placeholder="Describe the expected input format..."
                  value={inputFormat}
                  onChange={(e) => setInputFormat(e.target.value)}
                  className="format-textarea"
                />
                <textarea
                  placeholder="Describe the expected output format..."
                  value={outputFormat}
                  onChange={(e) => setOutputFormat(e.target.value)}
                  className="format-textarea"
                />
              </div>

              <div className="format-examples">
                {examples.map((example, index) => (
                  <div key={index} className="format-example">
                    <textarea
                      placeholder="Input example..."
                      value={example.input}
                      onChange={(e) => handleExampleChange(index, 'input', e.target.value)}
                      className="format-example-input"
                    />
                    <textarea
                      placeholder="Output example..."
                      value={example.output}
                      onChange={(e) => handleExampleChange(index, 'output', e.target.value)}
                      className="format-example-output"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveExample(index)}
                      className="remove-example-btn"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddExample}
                  className="add-example-btn"
                >
                  Add Example
                </button>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="next-btn">Start Training</button>
      </form>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Training Started</h3>
            <p>Check your email for progress updates</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FineTune;