import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DashboardComponents.css';
import logo from '../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFileAlt, 
  faRobot, 
  faCogs, 
  faHeadset
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ onLogout, currentUser }) => {
  const location = useLocation();
  
  // Calendly URL for both buttons
  const calendlyUrl = "https://calendly.com/gabrielbaki/oow-meeting-clone?timezone=America%2FLos_Angeles";
  
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <div className="sidebar-logo">
          <img src={logo} alt="TagflowAI Logo" />
          <h2>TagflowAI</h2>
        </div>
      </div>
      <nav className="sidebar-nav">
        <Link 
          to="/dashboard" 
          className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}
        >
          Training
        </Link>
        <Link 
          to="/dashboard/models" 
          className={`nav-item ${location.pathname === '/dashboard/models' ? 'active' : ''}`}
        >
          Models
        </Link>
        <Link 
          to="/dashboard/api-keys" 
          className={`nav-item ${location.pathname === '/dashboard/api-keys' ? 'active' : ''}`}
        >
          API Keys
        </Link>
        
        <div className="sidebar-divider"></div>
        
        {/* Comment out deep research sandbox link
        <Link 
          to="/dashboard/sandbox"
          className={`nav-item sandbox-nav-item ${location.pathname === '/dashboard/sandbox' ? 'active' : ''}`}
        >
          <div className="sandbox-link">
            <div className="sandbox-icons">
              <FontAwesomeIcon icon={faFileAlt} />
              <FontAwesomeIcon icon={faRobot} />
            </div>
            <span className="sandbox-title">Document Research Agent Sandbox</span>
            <span className="try-now-badge">Try it now!</span>
          </div>
        </Link>*/}

        <a 
          href={calendlyUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="nav-item sandbox-nav-item"
        >
          <div className="sandbox-link">
            <div className="sandbox-icons">
              <FontAwesomeIcon icon={faCogs} />
              <FontAwesomeIcon icon={faHeadset} />
            </div>
            <span className="sandbox-title">Customization, On-premise, Tech support</span>
            <span className="try-now-badge">Talk to us!</span>
          </div>
        </a>
      </nav>
      <div className="sidebar-footer">
        <span className="user-email">{currentUser}</span>
        <button onClick={onLogout} className="logout-btn">
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;