import React from 'react';
import './MainSection.css';

const MainSection = () => {
  return (
    <section className="main-section">
      <h1>Next Generation <span className="highlight">LLM Fine-Tuning</span> Platform</h1>
      <p>Fine-tune open-source LLMs with higher efficiency, lower costs, and minimal data.</p>
      
      <a href="https://calendly.com/gabrielbaki/oow-meeting-clone?timezone=America%2FLos_Angeles" target="_blank" rel="noopener noreferrer">
        <button className="request-demo-btn">Request a Demo</button>
      </a>

      <div className="stat-blocks">
        <div className="stat-block">
          <div className="stat-number">90%</div>
          <div className="stat-label">Cheaper than proprietary LLM</div>
        </div>
        <div className="stat-block">
          <div className="stat-number">5+</div>
          <div className="stat-label">Multimodal training input data<br/>(PDF, TXT, JPEG, DOCX)</div>
        </div>
        <div className="stat-block">
          <div className="stat-number">10x</div>
          <div className="stat-label">Synthetically generated data for better performance</div>
        </div>
      </div>

      <div className="partners">
        {/*<img src="/assets/images/harvard-logo.png" alt="Harvard" />
        <img src="/assets/images/aws-logo.png" alt="AWS" />
        <img src="/assets/images/microsoft-logo.png" alt="Microsoft" />
        <img src="/assets/images/ninjavan-logo.png" alt="Ninjavan" />*/}
      </div>
    </section>
  );
}

export default MainSection;
